import { FLUX_CONTRACT_TEST } from '../constants'


export function getCollectibleContract(chainId: number, web3: any) {

  const fluxAutomata = new web3.eth.Contract(
    FLUX_CONTRACT_TEST[chainId].abi,
    FLUX_CONTRACT_TEST[chainId].address
  )

  return fluxAutomata
}

