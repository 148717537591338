import * as React from 'react';
import styled from "styled-components";
import * as PropTypes from 'prop-types'
import Button from './Button';
import mask_image from "../assets/mask.png";
import app_image from "../assets/app.png";
import Iframe from 'react-iframe'
import { saveAs } from "file-saver";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useState } from 'react';

const STopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


const SMainContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const SViewerContainer = styled.div`
  height:  60vh;
  width: 40vw;
  min-width: 500px;
  max-width: 40vw;
  min-height: 805px;
  max-height: 805px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1.0);
  border-radius: 8px;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  flex: 2 1;
`;

const SViewerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SSparkContainerImage = styled.div`
  height: 40vh;
  min-height: 805px;
  max-height: 805px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1.0);
  border-radius: 8px;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  margin-bottom: 20px;
`;

const SSparkContainerApp = styled.div`
  height: 40vh;
  min-height: 805px;
  max-height: 805px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1.0);
  border-radius: 8px;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  margin-bottom: 20px;
  align-items: center;
`;

const SSparkContainerPfp = styled.div`
  height: 40vh;
  min-height: 805px;
  max-height: 805px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1.0);
  border-radius: 8px;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  margin-bottom: 20px;
`;

const SInfoText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: white;
`;

const SInstructionText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: white;
  text-align: left;
  padding-left: 10px;
`;

const SAppText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: white;
  text-align: left;
  padding-left: 10px;
  overflow-wrap: normal;
`;

const SInstructionTextBlue = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: blue;
  text-align: left;
  padding-left: 10px;
`;

const STraitTopContainer = styled.div`
  width: 24.0vw;
  height: 60vh;
  min-width: 490px;
  max-width: 490px;
  min-height: 805px;
  max-height: 805px;
  background-color: rgb(230,230,230);
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 75px;
  border: solid 6px black;
`;

const STraitContainer = styled.div`
  max-height: 100%;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const STrait = styled.div`
  min-width: 220px;
  background-color: lightblue;
  color: white;
  border-radius: 8px;
  border: 2px solid color: rgb(5, 119, 169);
  padding: 8px;
  margin: 8px 5px;
`;

const STraitType = styled.div`
  color: rgb(5, 119, 169);
  font-size: 16px;
  
`;

const STraitValue = styled.div`
  color: black;
  font-size: 20px;
`;

const STraitTitle = styled.span`
  color: white;
  font-size: 32px;
  font-style: bold;
  font-weight: 600;
  padding: 16px 198px;
  margin: -1px;
  background-color: black;
`;

const SSparkImage = styled.img`
  height: 642px;
`;

const SMaskImage = styled.img`
  height: 422px;
`;

const SAppImage = styled.img`
  height: 250px;
  width: 250px;
`;

const SReturnButton = styled(Button)`
  padding: 20px 20px;
  font-size: 24px;
  margin: 0 10px 20px;
  align-self: flex-start;
`;

const SDownloadButton = styled(Button)`
  padding: 20px 20px;
  font-size: 24px;
  color: black;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid black;
`;

const SStraightButton = styled(Button)`
  padding: 20px 20px;
  font-size: 24px;
  border-radius: 0;
  color: black;
  border: 2px solid black;
`;

const saveFile = (link: string, fileName: string) => {
  saveAs(link, fileName);
};

interface IOverviewProps {
  metadata: any
  returnToSelectorFunc: () => void
  tokenId: number
}

const linkReplace = (text: string) => {
  return text.replace("ipfs://", "https://matiria.mypinata.cloud/ipfs/");
}

const SparkDetails = (props: IOverviewProps) => {
  const { metadata, returnToSelectorFunc, tokenId } = props;
  
  const [FaceUrl, setFaceUrl] = useState('');
  const [LensUrl, setLensUrl] = useState('');
  const [AppUrl, setAppUrl] = useState('');

  const storage = getStorage();
  const gsReference = ref(storage, 'gs://fyatluxtest.appspot.com/faces_glb/' + tokenId + ".glb");
  const lensReference = ref(storage, "gs://fyatluxtest.appspot.com/LensStudio/FyatLuxDuality.zip");
  const appReference = ref(storage, "gs://fyatluxtest.appspot.com/android/FyatLuxAndroidApp_10038.apk");

  getDownloadURL(gsReference)
  .then((faceUrl) => {
    setFaceUrl(faceUrl);
  })

  getDownloadURL(lensReference)
  .then((lensUrl) => {
    setLensUrl(lensUrl);
  })

  getDownloadURL(appReference)
  .then((appUrl) => {
    setAppUrl(appUrl);
  })
  
  return (
    <>
      <STopContainer>
        <SReturnButton onClick={returnToSelectorFunc}>Return to Selection</SReturnButton>
        <SMainContainer>
        <SSparkContainerImage>
            <SInfoText>Duality Mask</SInfoText>
            <SMaskImage src={mask_image} alt={'image of person wearing Fyat Lux mask'}/>
            <SInstructionText>Instructions:</SInstructionText>
            <SInstructionTextBlue><a href="https://lensstudio.snapchat.com/">Download Lens Studio</a></SInstructionTextBlue>
            <SInstructionText>Open Fyat Lux Lens Project</SInstructionText>
            <SInstructionText>Drag mask file onto template</SInstructionText>
            <SViewerButtonContainer>
              <SStraightButton color="fyatyellow" onClick={() => saveFile(LensUrl, "FyatLux_Mask_LensStudio.zip")}>
                Download Lens Project
              </SStraightButton>
              <SDownloadButton color="fyatyellow" onClick={() => saveFile(FaceUrl, "SparkMask-" + tokenId + ".glb")}>
                Download Mask {tokenId}
              </SDownloadButton>             
            </SViewerButtonContainer> 
          </SSparkContainerImage>
          <SViewerContainer>
            <SInfoText>Interactive 3D Model</SInfoText>
            <Iframe url={'https://fyatlux-viewer.web.app/?tokenID=' + tokenId}
                  width="100%"
                  height="100%"
                  id="myId"
                  frameBorder={0}
                  className="myClassname"
                  />
            <SViewerButtonContainer>
              <SStraightButton color="fyatyellow" onClick={() => saveFile(linkReplace(metadata.model), "Spark-" + tokenId + ".glb")}>
                Download 3D Model
              </SStraightButton>
              <SDownloadButton color="fyatyellow" onClick={() => saveFile(linkReplace(metadata.tpose), "Spark-TPose-" + tokenId + ".glb")}>
                Download T-posed Model
              </SDownloadButton>
            </SViewerButtonContainer>                
          </SViewerContainer>
          <STraitTopContainer>
            <STraitTitle>Traits</STraitTitle>
            <STraitContainer>           
              {metadata.attributes.map((d: any, i: number) => (
              <STrait key={`${i}`}>
                <STraitType>
                  { d.trait_type }
                </STraitType>
                <STraitValue>
                  { d.value }
                </STraitValue>
              </STrait>
                          ))
                        }
            </STraitContainer>
          </STraitTopContainer>
          <SSparkContainerImage>
            <SInfoText>Card Image</SInfoText>
            <SSparkImage src={linkReplace(metadata.image)} alt={'image of ' + metadata.name}/>
            <SDownloadButton color="fyatyellow" onClick={() => saveFile(linkReplace(metadata.image), "Spark-Card-" + tokenId + ".jpg")}>
              Download Card Image
            </SDownloadButton>
          </SSparkContainerImage>
          <SSparkContainerPfp>
            <SInfoText>Profile Picture</SInfoText>
            <SSparkImage src={linkReplace(metadata.pfp)} alt={'image of ' + metadata.name}/>
            <SDownloadButton color="fyatyellow" onClick={() => saveFile(linkReplace(metadata.pfp), "Spark-Profile-" + tokenId + ".jpg")}>
              Download Profile Picture
            </SDownloadButton>
          </SSparkContainerPfp>
          <SSparkContainerApp>
            <SInfoText>Android App</SInfoText>
            <SAppImage src={app_image} alt={'image of android app'}/>
            <SAppText>If you do not have access to</SAppText>
            <SAppText>the Google Play Store, you</SAppText>
            <SAppText>can download the Android App here</SAppText>
            <SInstructionText>Version 1.03 (10038)</SInstructionText>
            <SViewerButtonContainer>
              <SDownloadButton color="fyatyellow" onClick={() => saveFile(AppUrl, "FyatLuxAndroidApp.apk")}>
                Download Android App
              </SDownloadButton>             
            </SViewerButtonContainer> 
          </SSparkContainerApp>
        </SMainContainer>
      </STopContainer>
    </>
  )
}

SparkDetails.propTypes = {
  wallet_id: PropTypes.string
}

export default SparkDetails;