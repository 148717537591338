import * as React from 'react'
import styled from "styled-components";
import transition_img from "../assets/black_transition.png";

const SBottomTransition = styled.div`
  height: 320px;
  width: 100vw;
  min-width: 320px;
  max-width: 100%;
  background: url(${transition_img}) no-repeat;
  background-color: rgba(255, 255, 255, 0.0);
  display: flex;
  justify-content: flex-start;
`;

const BottomTransition = () => {
  return (
      <SBottomTransition />
    )
}


export default BottomTransition;
