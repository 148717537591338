import * as React from 'react';
import styled from "styled-components";
import * as PropTypes from 'prop-types'
import ConnectButton from './ConnectButton';

const SProcessContainer = styled.div`
  min-height: 200px;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: 8px;
  justify-content: flex-start;
  padding: 20px 48px 32px;
  flex: 1 1 500px;
  align-items: center;
  align-self: flex-start;
  margin: auto;
`;

const SInfoText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
`;

const SButtonContainer = styled.div`
  display: flex;
  align-self: center;
  padding: 10px;
`;

const SSubTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const SWarning = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: red;

  @media (min-width: 768px) {
    display: none;
  }
`;

interface IOverviewProps {
  connectFunc: () => void
}

const ProcessOverview = (props: IOverviewProps) => {
  const { connectFunc } = props;

  return (
    <SProcessContainer>
      <SInfoText>Ready to see your Sparks?</SInfoText>
      <SSubTitle>Connect your wallet in order to see your collection!</SSubTitle>
      <SWarning>Note: If using mobile phones or tablet, select "WalletConnect" and then your wallet of choice.</SWarning>
      <SButtonContainer>
        <ConnectButton onClick={connectFunc} />
      </SButtonContainer>
    </SProcessContainer>
  )
}

ProcessOverview.propTypes = {
  connectFunc: PropTypes.func.isRequired
}

export default ProcessOverview;