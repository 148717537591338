import * as React from 'react';
import styled from "styled-components";

const SSparkContainer = styled.div`
  min-height: 200px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: 5px;
  justify-content: center;
  padding: 6px;
  margin: 35px;
  cursor: pointer;
  z-index: 0;

  transform: scale(1.0);
  transition-duration: 0.3s;

  &:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
    z-index: 2;
  }



`;

const SSparkImage = styled.img`
  height: 100%;
  width: 100%;
  max-height: 380px;
`;

const linkReplace = (text: string) => {
  return text.replace("ipfs://", "https://matiria.mypinata.cloud/ipfs/");
}

interface ISparkSelectorProps {
  selectFunc: (index: number, tokenId: number) => void
  metadata: any
  index: number
  tokenId: number
}

const SparkSelector = (props: ISparkSelectorProps) => {
  const { selectFunc, metadata, index, tokenId} = props;
  return (
    <SSparkContainer onClick={() => selectFunc(index, tokenId)}>
      <SSparkImage src={linkReplace(metadata.image)} alt={'image of ' + metadata.name}/>
    </SSparkContainer>
  )
}

export default SparkSelector;