import styled from "styled-components";
import bg from "../assets/bg_blur2.jpg";

const SBackground = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  min-width: 400px;
  background: url(${bg}) no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export default SBackground;
