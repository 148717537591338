import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
import metamaskLogo from "./assets/metamask.png";
import coinbaseLogo from "./assets/cb.png"
import MetaMaskOnboarding from '@metamask/onboarding';
import {WalletLink} from "walletlink";
import SBackground from "./components/Background";
import LandingBackground from "./components/LandingBackground";
import detectEthereumProvider from '@metamask/detect-provider';
import BottomTransition from "./components/bottom_transition";
import ProcessOverview from "./components/ProcessOverview";

import { convertUtf8ToHex } from "@walletconnect/utils";

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// @ts-ignore
import Fortmatic from "fortmatic";
// import Torus from "@toruslabs/torus-embed";

// @ts-ignore
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Modal from "./components/Modal";
import Header from "./components/Header";
import Loader from "./components/Loader";

import { apiGetAccountAssets, apiSubmitChallenge, apiGetCheckoutChallenge } from "./helpers/api";

import { alchemy, alchemyTest, metadataTest, metadataMain } from "./secret.json"

import {
  getChainData
} from "./helpers/utilities";

// @ts-ignore
import { IAssetData } from "./helpers/types";


import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import TermsOfService from "./components/TermsOfService";
import SparkDetails from "./components/SparkDetails";
import SparkSelector from "./components/SparkSelector";
import { getCollectibleContract } from "./helpers/web3";

const isTestnet = false;
let initChainId = 0

if(isTestnet){
  initChainId = 4
} else {
  initChainId = 1
}

const firebaseConfig = {
  apiKey: "AIzaSyAVH8WF5rsxJGCUkBMtpnvgssxpyo5d56Q",
  authDomain: "spark-viewer.firebaseapp.com",
  projectId: "spark-viewer",
  storageBucket: "spark-viewer.appspot.com",
  messagingSenderId: "914354584884",
  appId: "1:914354584884:web:186234fb4bee0aed513961",
  measurementId: "G-JQMTHPPT28"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const SLayout = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  min-width:390px;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 60vh;
  min-width: 390px;
  padding: 0 16px;
  background-color: black;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  background-color: white;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
  margin: 0px;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 20px;
  max-width: 300px
`;

const SModalSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const SConnectedContainer = styled.div`
  min-width: 390px;
  flex: 1;
  align-self: flex-start;
`;

const SSparkSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

let expectedChainId = -1;
if(isTestnet) {
  expectedChainId = 4;
} else {
  expectedChainId = 1;

}
interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  currentState: ViewerState;
  tokenMetadata: any[];
  tokenIds: number[];
  activeSparkIndex: number;

  requestTitle: string;
  requestText: string;
  errorTitle: string;
  errorText: string;
}

export enum ViewerState {
  CONNECT_WALLET,
  CHOOSE_SPARK,
  VIEW_SPARK,
  WRONG_NETWORK,
  TERMS_OF_SERVICE,
  ERROR
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: initChainId,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  currentState: ViewerState.CONNECT_WALLET,
  tokenMetadata: [],
  tokenIds: [],
  activeSparkIndex: -1,

  requestTitle: "",
  requestText: "",
  errorTitle: "",
  errorText: "",
};


const urlParams = new URLSearchParams(window.location.search);


if(window.opener != null) {
  // On Server view after response
  window.opener.postMessage(
    { auth: { token: urlParams.get("access") } },
    "*"
  );
}



function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      disableInjectedProvider: true,
      network: this.getNetwork(),
      cacheProvider: false,
      providerOptions: this.getProviderOptions()
    });
  }

  public checkout = async () => {
    const { web3, address } = this.state;

    if (!web3) {
      return;
    }

    this.setState({ requestTitle: "Fetching Ownership Challenge...", showModal: true, pendingRequest: true });
    const challenge = await apiGetCheckoutChallenge(address, 4)
    console.log(challenge)
    const hexMsg = convertUtf8ToHex(challenge);

    try {
        this.setState({ requestTitle: "Awaiting Owner Signature...", showModal: true, pendingRequest: true });
        const result = await web3.eth.personal.sign(hexMsg, address);
        this.setState({ requestTitle: "Fetching checkout...", showModal: true, pendingRequest: true });
        const checkoutLink = await apiSubmitChallenge(address, result, 4)
        this.setState({ requestTitle: "Loading...", showModal: false, pendingRequest: false });
        console.log(result)
        window.location.href = checkoutLink
    } catch(error) {
      console.log("failure signing message")
      this.setState({ showModal: true, pendingRequest: false, currentState: ViewerState.ERROR, errorTitle: "There was an error claiming your card.", errorText: "Please make sure you are connecting the correct wallet." });

    }

  };

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
  }


  public onConnect = async () => {
    logEvent(analytics, "viweer-connect-start");

    await this.web3Modal.clearCachedProvider()
    const provider = await this.web3Modal.connect();
    this.setState({ requestTitle: "Loading...", showModal: true, pendingRequest: true });

    await this.subscribeProvider(provider);

    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();

    if(expectedChainId !== chainId) {
      await this.setState({
          web3,
          provider,
          connected: true,
          address,
          chainId,
          networkId,
          showModal: true,
          currentState: ViewerState.WRONG_NETWORK
        });
        logEvent(analytics, "mint-connect-wrong-network");
    } else {
         await this.setState({
          web3,
          provider,
          connected: true,
          address,
          chainId,
          networkId,
          currentState: ViewerState.CHOOSE_SPARK
        });
    }
    
    await this.getAccountAssets();
    const success = await this.retrieveTokens();

    this.setState({ requestTitle: "", showModal: false, pendingRequest: false });
    
    if (!success){
      this.setState({ showModal: true, currentState: ViewerState.ERROR, errorTitle: "Sorry, no Sparks found in this wallet", errorText: "Please make sure you are connecting the correct wallet." });
    }

    logEvent(analytics, "mint-connect-finish");
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());

    provider.on("disconnect", () => this.resetApp());

    provider.on("accountsChanged", async (accounts: string[]) => {
      if(accounts[0] === undefined) {
        this.resetApp()
      } else {
        await this.setState({address: accounts[0]});
        await this.getAccountAssets();
      }
    });

    provider.on("chainChanged", async (chainId: number) => {
      const { web3, address } = this.state;
      console.log("CHAIN has changed")
      const newChain = chainId + ""
      const newChainInt = parseInt(newChain, 16)
      console.log(newChainInt)

      let expectedChainId = -1;
      if(isTestnet) {
        expectedChainId = 4;
      } else {
        expectedChainId = 1;
      }
      const networkId = await web3.eth.net.getId();
      if(expectedChainId === newChainInt) {
        if(this.state.currentState === ViewerState.WRONG_NETWORK) {
          this.setState({ requestTitle: "Loading",pendingRequest: true });
          this.setState({ pendingRequest: false });

         await this.setState({
            web3,
            address,
            newChainInt,
            networkId,
            currentState: ViewerState.CONNECT_WALLET
          });

        } else {
          await this.setState({newChainInt, networkId});
        }
      } else {
        await this.setState({newChainInt, networkId, showModal: true, currentState: ViewerState.WRONG_NETWORK});
      }
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();

      await this.setState({ chainId, networkId });
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      "custom-metamask": {
          display: {
            logo: metamaskLogo,
            name: "MetaMask",
            description: "Connect with your MetaMask Wallet"
          },
          package: true,
          connector: async () => {
              if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
                  window.location.assign("https://metamask.app.link/dapp/www.ethbox.org/app/");
                  return;
              }

              let provider: any
              if (typeof window.ethereum !== 'undefined') {
                  // @ts-ignore
                const providers: any = window.ethereum.providers;

                  if(providers !== undefined) {
                    console.log(providers)
                    provider = providers.find((p: any) => p.isMetaMask);// <-- LOOK HERE
                    try {
                      await provider.request({method: 'eth_requestAccounts'});
                    } catch (error) {
                      throw new Error("User Rejected");
                    }

                  } else {
                      provider = await detectEthereumProvider();
                      try {
                              await provider.request({ method: 'eth_requestAccounts' });
                      } catch (error) {
                          throw new Error("User Rejected");
                      }
                  }
              } else {
                  throw new Error("No MetaMask Wallet found");
              }

              return provider;
          }
      },
        'custom-coinbase': {
    display: {
      logo: coinbaseLogo,
      name: 'Coinbase Wallet',
      description: 'Connect with your Coinbase Wallet',
    },
    options: {
      appName: 'Fyat Lux', // Your app name
      networkUrl: alchemy,
      chainId: expectedChainId,
    },
    package: WalletLink,
    connector: async (_:any, options:any) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
    },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            1: alchemy,
            4: alchemyTest
          }
        }
      },
      // torus: {
      //   package: Torus
      // },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: {1: "pk_live_62B7D2F197CDA179", 4: "pk_test_75979444702140C4" }[expectedChainId]
        }
      },
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public retrieveTokens = async () => {
    const { address, chainId } = this.state;
    let setup = null;
    if (isTestnet){
      setup = new Web3.providers.HttpProvider(metadataTest);
    }
    else {
      setup = new Web3.providers.HttpProvider(metadataMain);
    }
    const web3 = new Web3(setup);

    const fluxAutomata = getCollectibleContract(chainId, web3);
    const balanceOfAddress = await fluxAutomata.methods.balanceOf(address).call();
    console.log(fluxAutomata);

    let token_metadata: any[] = [];
    let token_ids: any[] = [];

    for(let i = 0; i < balanceOfAddress; i++) {
      const tokenId = await fluxAutomata.methods.tokenOfOwnerByIndex(address, i).call();
      console.log(tokenId)
      token_ids = token_ids.concat(tokenId);
      const token = await fluxAutomata.methods.tokenURI(tokenId).call()
      console.log(token)
      const metadata = await (await fetch(token)).json();
      console.log(metadata)
      token_metadata = token_metadata.concat(metadata);
    }
    
    this.setState({
      tokenMetadata: token_metadata,
      tokenIds: token_ids
    });

    return token_ids.length > 0;
  }

  public showTermsOfService = () => {
      this.setState({currentState: ViewerState.TERMS_OF_SERVICE })
   }

  public toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  public selectSpark = (index: number) => {
    this.setState({activeSparkIndex: index, currentState: ViewerState.VIEW_SPARK })
  }

  public exitDetails = () => {
    this.setState({activeSpark: -1, currentState: ViewerState.CHOOSE_SPARK })
  }

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }

    // this.web3Modal.cachedProvider = ""; // This is referring to your instance not the class
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public render = () => {
    const {
      assets,
      address,
      connected,
      chainId,
      showModal,
      pendingRequest,
      currentState,
      tokenMetadata,
      tokenIds,
      activeSparkIndex,
      requestTitle,
      requestText,
      errorTitle,
      errorText,
    } = this.state;
    return (
      <SLayout>
        <SBackground>
          <Column spanHeight>
            <Header
              connected={connected}
              address={address}
              chainId={chainId}
              killSession={this.resetApp}
            />
            <SContent center>
              {(!!assets && !!assets.length) ? (                
                currentState === ViewerState.CHOOSE_SPARK ?  (
                  <>
                    <SSparkSelectionContainer>
                    {tokenMetadata.map((d, i) => (
                     <SparkSelector selectFunc={this.selectSpark} tokenId={tokenIds[i]} index={i} key={`${d.name}`} metadata={d} />   
                      ))
                    }
                    </SSparkSelectionContainer>
                  </>
                ) : (currentState === ViewerState.VIEW_SPARK && activeSparkIndex >= 0) ?  (                  
                    <SparkDetails tokenId={tokenIds[activeSparkIndex]} metadata={tokenMetadata[activeSparkIndex]} returnToSelectorFunc={this.exitDetails} />
                ) : currentState === ViewerState.TERMS_OF_SERVICE &&  (
                  <TermsOfService closeFunc={this.resetApp}/>
                )
                ) : !pendingRequest &&
                (
                  <LandingBackground>             
                    <ProcessOverview connectFunc={this.onConnect}/>
                  </LandingBackground>
                )
              }
            </SContent>
            <SConnectedContainer>
              <BottomTransition />        
            </SConnectedContainer> 
          </Column>
        </SBackground>
        <Modal show={showModal} toggleModal={this.resetApp}>
            { pendingRequest ? (
            <SModalContainer>
              <SModalSection>
                <SContainer>
                  <SModalTitle>{ requestTitle }</SModalTitle>
                  <Loader />
                  <SModalParagraph>
                    { requestText }
                  </SModalParagraph>
                </SContainer>
              </SModalSection>
            </SModalContainer>
          ) : currentState === ViewerState.WRONG_NETWORK ?  (
            <SModalContainer>
              <SModalSection>
                <SContainer>
                  <SModalTitle>{"Wrong Network Detected"}</SModalTitle>
                  <SModalParagraph>
                    { isTestnet ? "Make sure you are connected to the Rinkeby Network" : "Make sure you are connected to the  Main Network." }
                  </SModalParagraph>
                </SContainer>
              </SModalSection>
            </SModalContainer>
          ) : currentState === ViewerState.ERROR ? (
            <SModalContainer>
              <SModalSection>
                <SContainer>
                  <SModalTitle>{errorTitle}</SModalTitle>
                  <SModalParagraph>
                    { errorText }
                  </SModalParagraph>
                </SContainer>
              </SModalSection>
            </SModalContainer>
          ) : 
            <SModalContainer>
              <SModalSection>
                <SContainer>
                  <SModalTitle>{"Success"}</SModalTitle>
                </SContainer>
              </SModalSection>
            </SModalContainer>
        }
        </Modal>
      </SLayout>
    );
  };
}

export default App;