import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import { ellipseAddress } from '../helpers/utilities'
import { transitions } from '../styles'
import top_yellow_transition from "../assets/top_yellow_transition.png";
import logo_black from "../assets/logo_black.png";

const SHeader = styled.div`
  background: url(${top_yellow_transition}) no-repeat;
  background-size: contain;
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100vw;
  min-height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px;
  align-self: flex-start;
`;

// @ts-ignore
const STopTransition = styled.div`
  width: 100%;
  height: 100%;
  min-height: 350px;
  background: url(${top_yellow_transition}) no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
`;

const SLogo = styled.div`
  background: url(${logo_black}) no-repeat;
  width: 300px;
  height: 150px;
  min-height: 150px
  z-index: 2;
`;

const SActiveAccount = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  align-self: flex-start;
  align-items: flex-start;
  font-weight: 500;
  margin-top: 17px;
  margin-right: 20px;
`

interface IHeaderStyle {
  connected: boolean
}

const SAddress = styled.p<IHeaderStyle>`
  transition: ${transitions.base};
  color: black;
  margin: 0;
`;

const SConnected = styled.p`
  transition: ${transitions.base};
  font-weight: bold;
  color: black;
  margin: 0;
`;

const SDisconnect = styled.div<IHeaderStyle>`
  background-color: white;
  font-size: 14px;
  font-family: monospace;
  position: absolute;
  right: 228px;
  top: 9px;
  color: black;
  opacity: 1;
  cursor: pointer;
  visibility: ${({ connected }) => (connected ? 'visible' : 'hidden')};
  pointer-events: ${({ connected }) => (connected ? 'auto' : 'none')};
  border: 2px solid black;
  padding: 5px;
  &:hover {
    transform: translateY(-1px);
    opacity: 0.7;
  }
`

const SClaim = styled.div<IHeaderStyle>`
  background-color: white;
  font-size: 14px;
  font-family: monospace;
  width: 120px;
  position: absolute;
  right: 350px;
  top: 9px;
  color: black;
  opacity: 1;
  cursor: pointer;
  visibility: ${({ connected }) => (connected ? 'visible' : 'hidden')};
  pointer-events: ${({ connected }) => (connected ? 'auto' : 'none')};
  border: 2px solid black;
  padding: 5px;
  &:hover {
    transform: translateY(-1px);
    opacity: 0.7;
  }
`

interface IHeaderProps {
  killSession: () => void
  checkout?: () => void
  connected: boolean
  address: string
  chainId: number
}

const Header = (props: IHeaderProps) => {
  const { connected, address, killSession, checkout } = props;
  return (
    <SHeader {...props}>
      <SLogo />        
      {address && (
        <SActiveAccount>
          <SConnected>Connected to: &nbsp;</SConnected>
          <SAddress connected={connected}>{ellipseAddress(address)}</SAddress>
          <SDisconnect connected={connected} onClick={killSession}>
            {'Disconnect'}
          </SDisconnect>
          {checkout && (<SClaim connected={connected} onClick={checkout}>
            {'Claim Card'}
          </SClaim>)}
        </SActiveAccount>
      )}
    </SHeader>
  )
}

Header.propTypes = {
  killSession: PropTypes.func.isRequired,
  checkout: PropTypes.func,
  address: PropTypes.string
}

export default Header
