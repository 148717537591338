export const FLUX_CONTRACT_TEST = {
    1: {
    constant: false,
    address: '0x14C4471A7F6dCAc4F03a81Ded6253eacEfF15B3d',
    abi: [
      {
        inputs: [
          {
            name: "owner",
            type: "address"
          }
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
     },
     {
            inputs: [
              {
                name: "owner",
                type: "address"
              },
              {
                name: "index",
                type: "uint256"
              }
            ],
            name: "tokenOfOwnerByIndex",
            outputs: [
              {
                name: "",
                type: "uint256"
              }
            ],
            stateMutability: "view",
            type: "function",
            constant: true
      },
      {
        inputs: [],
        name: "PRICE",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256"
          }
        ],
        name: "tokenURI",
        outputs: [
          {
            name: "",
            type: "string"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      }
    ]
    },
  4: {
    constant: false,
    address: '0xf1fC0de4ef6E447afad99179187Bc7a2C44AC28B',
    abi: [
        {
              inputs: [
                {
                  name: "owner",
                  type: "address"
                }
              ],
              name: "balanceOf",
              outputs: [
                {
                  name: "",
                  type: "uint256"
                }
              ],
              stateMutability: "view",
              type: "function",
              constant: true
           },
        {
              inputs: [
                {
                  name: "owner",
                  type: "address"
                },
                {
                  name: "index",
                  type: "uint256"
                }
              ],
              name: "tokenOfOwnerByIndex",
              outputs: [
                {
                  name: "",
                  type: "uint256"
                }
              ],
              stateMutability: "view",
              type: "function",
              constant: true
         },
      {
        inputs: [],
        name: "PRICE",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256"
          }
        ],
        name: "tokenURI",
        outputs: [
          {
            name: "",
            type: "string"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      }
    ]
    }
}
